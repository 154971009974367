import { useCallback, useEffect } from 'react'
import { saveAs } from 'file-saver'
import { Anchor } from 'grommet'
import { useFormContext } from 'react-hook-form'
import * as yup from 'yup'

import { Box, Message, Text, useInitialMount, useNotify } from '@cutover/react-ui'
import { ApiResponse, downloadRunbookCSVTemplate, importTasksCsv } from './import-tasks-csv'
import { CheckboxField, FileInputField, FormModal, TimezoneSelectField } from 'main/components/shared/form'
import { useLanguage } from 'main/services/hooks'
import { getClientIANATimezone } from 'main/services/timezone-util'
import { ActiveRunbookModel, ActiveRunbookVersionModel, RunbookViewModel } from 'main/data-access'
import { TaskCSVImportModalType } from 'main/recoil/runbook'

const validationSchema = yup.object().shape({
  file: yup.mixed().required('You must choose a file'),
  timezone: yup.string().required(),
  overwrite: yup.bool(),
  useCentralTeams: yup.bool()
})

export type CsvImportForm = yup.InferType<typeof validationSchema>

type CsvImportFormProps = {
  open: boolean
  closeModal: () => void
  planningMode: boolean
  resourceType: string
}

export const CsvImportModal = ({ open, planningMode, resourceType, closeModal }: CsvImportFormProps) => {
  const { t } = useLanguage('runbook')
  const notify = useNotify()
  const timezone = getClientIANATimezone()
  const runbookId = ActiveRunbookModel.useId()
  const runbookVersionId = ActiveRunbookVersionModel.useId()

  const updateModal = RunbookViewModel.useAction('modal:update')
  const { active } = RunbookViewModel.useGet('modal')
  const modalData = (active as TaskCSVImportModalType)?.data

  const clearState = () => {
    closeModal()
  }

  const onSubmit = useCallback(
    async (data: CsvImportForm): Promise<{ formData: FormData } | undefined> => {
      const formData = new FormData()
      if (data) {
        formData.append('file', data.file as File)
        formData.append('timezone', data.timezone)
        formData.append('overwrite', JSON.stringify(data.overwrite))
        formData.append('use_central_teams', JSON.stringify(data.useCentralTeams))

        updateModal({ status: 'submitting' })
        return { formData }
      }
    },
    [runbookId, runbookVersionId, updateModal]
  )

  const clickSubmit = async (data: CsvImportForm) => {
    const result = await onSubmit(data)
    if (!result) return

    const { formData } = result
    return await importTasksCsv({ formData, runbookId, runbookVersionId })
  }

  useEffect(() => {
    if (modalData?.status === 'success') {
      notify.success(t('csvImportModal.successMessage'))
      clearState()
    }
  }, [modalData?.status])

  const downloadTemplate = async () => {
    try {
      const csvTemplate: ApiResponse = await downloadRunbookCSVTemplate()
      const file = new Blob([csvTemplate.data], { type: 'text/csv' })
      const fileName = 'RunbookTemplate.csv'
      saveAs(file, fileName)
      notify.success(t('csvImportModal.templateDownloadSuccess'))
    } catch (error) {
      notify.error(t('csvImportModal.templateDownloadError'))
    }
  }

  return (
    <FormModal<CsvImportForm>
      title={t('csvImportModal.title')}
      confirmText="Import"
      confirmIcon="save"
      open={open}
      onClose={() => clearState()}
      onSubmit={clickSubmit}
      loading={modalData?.status === 'submitting'}
      reValidateMode="onSubmit"
      schema={validationSchema}
      preventAutoClose={true}
      customErrors={modalData?.status === 'error' ? modalData.context : []}
      description={
        <Text>
          {t('csvImportModal.description')}{' '}
          <Anchor href={t('csvImportModal.learnMoreLink')} label={t('csvImportModal.learnMoreText')} />
          <br />
          <br />
          <Anchor
            tabIndex={0}
            onKeyDown={e => {
              if (e.code === 'Enter') {
                downloadTemplate()
              }
            }}
            onClick={downloadTemplate}
          >
            {t('csvImportModal.downloadTemplate')}
          </Anchor>
        </Text>
      }
      defaultValues={{
        file: undefined,
        timezone: timezone,
        overwrite: false,
        useCentralTeams: false
      }}
    >
      <CsvImportFormFields showCheckbox={planningMode} resourceType={resourceType} />
    </FormModal>
  )
}
type CsvImportFormFieldsProps = {
  showCheckbox: boolean
  resourceType: string
  timezone?: any
}

const CsvImportFormFields = ({ showCheckbox, resourceType }: CsvImportFormFieldsProps) => {
  const isFirstMount = useInitialMount()
  const { t } = useLanguage('runbook')
  const { watch, reset } = useFormContext()

  const updateModalStatus = RunbookViewModel.useAction('modal:update')
  const activeModal = RunbookViewModel.useGet('modal').active
  const status = (activeModal as TaskCSVImportModalType)?.data?.status

  const isSnippet = resourceType === 'Snippet'
  const fileInputValue = watch('file')

  useEffect(() => {
    if (isFirstMount) return
    reset({ file: fileInputValue })
    updateModalStatus({ context: undefined, status: 'idle' })
  }, [fileInputValue])

  return (
    <>
      <Box gap="medium">
        {isSnippet && <Message message={t('csvImportModal.snippetInfo')} type="info" />}
        <FileInputField<CsvImportForm> name="file" disabled={status === 'submitting'} accept="text/csv" inlineError />
        <TimezoneSelectField<CsvImportForm>
          name="timezone"
          css="width: 100%"
          label={t('csvImportModal.timezoneLabel', { resourceType: resourceType.toLowerCase() })}
          disabled={status === 'submitting'}
          customFirstOption={null}
          required
        />
      </Box>
      {showCheckbox && (
        <CheckboxField<CsvImportForm>
          name="overwrite"
          disabled={status === 'submitting'}
          label={t('csvImportModal.overwriteLabel')}
        />
      )}
      {showCheckbox && (
        <CheckboxField<CsvImportForm>
          name="useCentralTeams"
          disabled={status === 'submitting'}
          label={t('csvImportModal.useCentralTeamsLabel')}
        />
      )}
    </>
  )
}
