import { useRecoilTransaction_UNSTABLE, useRecoilValueLoadable, useResetRecoilState } from 'recoil'

import { LoadableType } from '@cutover/utility-types'
import {
  accountResponseState_INTERNAL,
  runbookResponseState_INTERNAL,
  runbookVersionResponseState_INTERNAL,
  taskListResponseState_INTERNAL
} from 'main/recoil/runbook'
import { GetAccountResponseType } from 'main/services/api/data-providers/account/use-get-account-data'
import { useEnsureStableArgs } from 'main/data-access/models/model-utils'
import { GlobalStateSetupModel, RecoilDataMap } from 'main/data-access/models/global-state-setup-model'
import { RunbookGetResponse } from 'main/services/api/data-providers/runbook-types'
import { GetRunbookVersionResponse } from 'main/services/queries/use-runbook-versions'
import { TaskListResponseType } from 'main/services/queries/use-tasks'
import { taskListResponseDataHelpers } from './task-list-response-data-helpers'

/* -------------------------------------------------------------------------- */
/*                                    Load                                    */
/* -------------------------------------------------------------------------- */

export const useSetGlobalState: GlobalStateSetupModel['useSet'] = type => {
  useEnsureStableArgs(type)

  /* eslint-disable react-hooks/rules-of-hooks */
  switch (type) {
    case 'account':
      return useSetAccount() as typeof useSetAccount
    case 'runbook':
      return useSetRunbook() as typeof useSetRunbook
    case 'runbookVersion':
      return useSetRunbookVersion() as typeof useSetRunbookVersion
    case 'tasks':
      return useSetTasks() as typeof useSetTasks
    default:
      throw new Error(`Unknown type: ${type}`)
    /* eslint-enable react-hooks/rules-of-hooks */
  }
}

const useSetAccount = () =>
  useRecoilTransaction_UNSTABLE(({ set }) => (response: GetAccountResponseType) => {
    set(accountResponseState_INTERNAL, response)
  })

const useSetRunbook = () =>
  useRecoilTransaction_UNSTABLE(({ set }) => (response: RunbookGetResponse) => {
    set(runbookResponseState_INTERNAL, response)
  })

const useSetRunbookVersion = () =>
  useRecoilTransaction_UNSTABLE(({ set }) => (response: GetRunbookVersionResponse) => {
    set(runbookVersionResponseState_INTERNAL, response)
  })

const useSetTasks = () =>
  useRecoilTransaction_UNSTABLE(({ set, get }) => (response: TaskListResponseType) => {
    const rbvResponse = get(runbookVersionResponseState_INTERNAL)
    if (!rbvResponse?.meta?.runbook_components) throw new Error('No runbook version request data to create lookups')

    set(
      taskListResponseState_INTERNAL,
      taskListResponseDataHelpers.extend(response, { runbookComponents: rbvResponse.meta.runbook_components })
    )
  })

/* -------------------------------------------------------------------------- */
/*                                    Clear                                   */
/* -------------------------------------------------------------------------- */

export const useResetGlobalState: GlobalStateSetupModel['useReset'] = type => {
  const resetAccount = useResetRecoilState(accountResponseState_INTERNAL)
  const resetRunbook = useResetRecoilState(runbookResponseState_INTERNAL)
  const resetRunbookVersion = useResetRecoilState(runbookVersionResponseState_INTERNAL)
  const resetTasks = useResetRecoilState(taskListResponseState_INTERNAL)

  switch (type) {
    case 'account':
      return resetAccount
    case 'runbook':
      return resetRunbook
    case 'runbookVersion':
      return resetRunbookVersion
    case 'tasks':
      return resetTasks
    default:
      throw new Error(`Unknown type: ${type}`)
  }
}

/* -------------------------------------------------------------------------- */
/*                                  Loadable                                  */
/* -------------------------------------------------------------------------- */

export const useLoadableGlobalState = <TType extends keyof RecoilDataMap>(type: TType) => {
  useEnsureStableArgs(type)

  /* eslint-disable react-hooks/rules-of-hooks */
  switch (type) {
    case 'account':
      return useRecoilValueLoadable(accountResponseState_INTERNAL) as LoadableType<RecoilDataMap[TType]>
    case 'runbook':
      return useRecoilValueLoadable(runbookResponseState_INTERNAL) as LoadableType<RecoilDataMap[TType]>
    case 'runbookVersion':
      return useRecoilValueLoadable(runbookVersionResponseState_INTERNAL) as LoadableType<RecoilDataMap[TType]>
    case 'tasks':
      return useRecoilValueLoadable(taskListResponseState_INTERNAL) as LoadableType<RecoilDataMap[TType]>
    default:
      throw new Error(`Unknown type: ${type}`)
    /* eslint-enable react-hooks/rules-of-hooks */
  }
}
