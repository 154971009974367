import { FocusEventHandler, memo, Ref, useEffect, useMemo, useRef, useState } from 'react'
import { debounce } from 'lodash'
import { useMergeRefs } from 'use-callback-ref'
import { useUnmount } from 'react-use'

import { Box, duration as durationFormatter, ListItem, MultiSelect, Text, useNotify } from '@cutover/react-ui'
import {
  SearchableCustomFieldOption,
  SearchableCustomFieldResponse
} from 'main/services/api/data-providers/user/user-channel-response-types'
import { useLanguage, useUserWebsocket } from 'main/services/hooks'
import { CustomField } from 'main/services/queries/types'
import {
  DataSourceRefreshRequestType,
  DataSourceSearchFormType,
  useRefreshDataSourceMutation,
  useSearchDataSourceMutation
} from 'main/services/queries/use-data-sources'
import { SharedViewModel } from 'main/data-access/view-models/shared-view-model'

const DEBOUNCE_TIME_MILIS = 300
const MIN_CHARS = 1

// TODO: check ms graph handling in searchable_custom_field_display_directive.js line 219

type SearchableCustomFieldProps = {
  customField: CustomField
  value?: SearchableCustomFieldOption[]
  multiSelect?: boolean
  onChange: (value?: SearchableCustomFieldOption[]) => void
  hasError?: boolean
  inlineError?: string
  required?: boolean
  disabled?: boolean
  readOnly?: boolean
  inputRef?: Ref<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
  taskId?: number | string
  hideDependentFields?: boolean
}

export const SearchableCustomField = ({
  value,
  onChange,
  multiSelect,
  customField,
  ...props
}: SearchableCustomFieldProps) => {
  const [selectValue, setSelectValue] = useState<SearchableCustomFieldOption[] | undefined>(value ?? [])

  useEffect(() => {
    setSelectValue(value?.filter(item => !item.destroy) ?? [])
  }, [value])

  const updateItemsForDestroy = (selectedItems?: SearchableCustomFieldOption[]) => {
    const selectedItemPrimaryKeys = new Set(selectedItems?.map(item => item.primaryKey) ?? [])
    let items = [...(selectedItems ?? [])]

    value?.forEach(item => {
      if (item.id && !selectedItemPrimaryKeys.has(item.primaryKey)) {
        // mark item for deletion
        items = [...items, { ...item, destroy: true }]
      } else {
        delete item.destroy

        if (multiSelect) {
          items = items.filter(i => i.primaryKey !== item.primaryKey)
          items.push({ ...item })
        } else {
          items = [item]
        }
      }
    })

    return items
  }

  return (
    <CustomSelect
      {...props}
      customField={customField}
      value={selectValue}
      onChange={val => {
        const nextVal = multiSelect ? val : !val?.length ? [] : [val[val.length - 1]]
        onChange?.(updateItemsForDestroy(nextVal))
      }}
      multiSelect={multiSelect}
    />
  )
}

const CustomSelect = memo(
  ({
    customField,
    value = [],
    multiSelect = false,
    onChange,
    hasError,
    inputRef: forwardedRef,
    inlineError,
    disabled,
    readOnly,
    required,
    onBlur,
    taskId,
    hideDependentFields = false
  }: SearchableCustomFieldProps) => {
    const { t } = useLanguage('customFields')

    const customFieldRefreshState = SharedViewModel.useGet('customFieldRefresh')
    const setCustomFieldRefreshState = SharedViewModel.useAction('customFieldRefresh:update')
    const isCustomFieldRefreshing = customFieldRefreshState[customField.id]?.refresh
    const stateErrors = customFieldRefreshState[customField.id]?.errors

    const hasRefreshErrors = useMemo(() => {
      if (stateErrors) {
        return value.some(v => (v.id ? !!stateErrors[v.id] : false))
      }
      return false
    }, [value, customFieldRefreshState, customField.id])

    const refreshErrorString = useMemo(() => {
      if (!stateErrors) return ''

      const primaryKeys = value.filter(v => v.id && stateErrors[v.id]).map(v => v.primaryKey)

      return primaryKeys.length > 0
        ? t('edit.searchableCustomField.errorLabel', { primaryKeys: primaryKeys.join(', '), count: primaryKeys.length })
        : ''
    }, [value, stateErrors])

    const localRef = useRef<HTMLInputElement>(null)
    const inputRef = useMergeRefs(forwardedRef ? [localRef, forwardedRef] : [localRef])
    const [loading, setLoading] = useState(false)
    const [canShowNoResults, setCanShowNoResults] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [options, setOptions] = useState<SearchableCustomFieldOption[]>([])
    const search = useSearchDataSourceMutation()
    const refresh = useRefreshDataSourceMutation()
    const [isRefreshing, setIsRefreshing] = useState(false)
    const { listen } = useUserWebsocket()
    const notify = useNotify()
    const showRefreshMessage = value.length > 0 && value[0].updatedAt

    const updateData = async (query: string) => {
      const isEmpty = query?.trim()?.length === 0

      if (isEmpty) {
        setOptions([])
        return
      }

      const data: DataSourceSearchFormType = {
        customFieldId: customField.id,
        query: query,
        taskId: Number(taskId)
      }

      search.mutate(data, {
        onError: () => notify.error(t('edit.notification.refreshError'))
      })
    }

    // Generates a compact duration distance with single unit precision, eg 'Updated 4h ago'
    const refreshString = useMemo(() => {
      let result = ''

      const updatedValue =
        value.reduce((latest: Date | undefined, item: SearchableCustomFieldOption) => {
          if (!item.updatedAt) return latest
          return !latest || new Date(item.updatedAt) > new Date(latest) ? new Date(item.updatedAt) : latest
        }, undefined) ?? new Date()
      const now = new Date()
      const secondsDiff = (now.getTime() - updatedValue.getTime()) / 1000

      if (secondsDiff < 60) {
        result = t('edit.searchableCustomField.dateDistanceNow')
      } else {
        result = t('edit.searchableCustomField.dateDistance', {
          distance: durationFormatter(secondsDiff, 1)
        })
      }

      return result
    }, [value])

    const handleInputChange = debounce((input: string) => {
      if (input.length < MIN_CHARS) {
        setCanShowNoResults(false)
      } else {
        setLoading(true)
      }
      setInputValue(input)
      updateData(input)
    }, DEBOUNCE_TIME_MILIS)

    const handleRefresh = () => {
      setCustomFieldRefreshState({ customFieldId: customField.id, refreshState: { refresh: true } })

      const data: DataSourceRefreshRequestType = {
        customFieldId: customField.id,
        values: []
      }
      value.map(option => {
        if (option.id) {
          data.values.push({
            query: option.primaryKey,
            field_value_id: option.id
          })
        }
      })

      setIsRefreshing(true)
      refresh.mutate(data)
    }

    // Success or error notification can only be determined once websocket for remote search is received.
    useEffect(() => {
      const currentState = customFieldRefreshState[customField.id]
      if (
        !currentState ||
        currentState.refresh ||
        !isRefreshing ||
        (currentState.errors && Object.keys(currentState.errors).length < value.length)
      ) {
        return
      }

      if (!!hasRefreshErrors) {
        notify.error(t('edit.notification.refreshError'))
      } else {
        notify.success(t('edit.notification.refreshSuccess'))
      }
      setIsRefreshing(false)
    }, [customFieldRefreshState[customField.id]])

    const unsetLoading = debounce(
      () => {
        setLoading(false)
        setCanShowNoResults(true)
      },
      DEBOUNCE_TIME_MILIS / 2,
      { leading: true }
    )

    useEffect(() => {
      const handleUserChannelUpdate = (data: SearchableCustomFieldResponse) => {
        const results = data?.results?.map(d => {
          return {
            primaryKey: d.values[customField.display_name || customField.name],
            dataSourceValueId: d.id,
            values: d.values
          }
        })
        const searchableFieldId = data.meta.headers.searchable_field_id
        if (Number(searchableFieldId) === customField.id) {
          setOptions(results ?? [])
        } else {
          setOptions([])
        }
        unsetLoading()
      }

      listen(data => {
        handleUserChannelUpdate(data as SearchableCustomFieldResponse)
      })
    }, [])

    useUnmount(() => {
      setCustomFieldRefreshState({ customFieldId: customField.id, refreshState: { refresh: false } })
    })

    return (
      <Box
        flex={false}
        css={`
          [aria-label='close'] {
            display: none;
          }
        `}
      >
        <MultiSelect
          label={customField.display_name || customField.name}
          inlineError={hasRefreshErrors ? refreshErrorString : inlineError}
          hasError={hasError}
          required={required}
          onInputChange={value => {
            setCanShowNoResults(false)
            handleInputChange(value)
          }}
          options={options}
          value={value}
          hideInputIfReadOnly
          single={!multiSelect}
          icon="search"
          onChange={onChange}
          onBlur={onBlur}
          resetInputOnBlur
          inputRef={inputRef}
          loading={loading}
          disabled={disabled}
          minChars={MIN_CHARS}
          emptyMessage={canShowNoResults ? t('edit.searchableCustomField.noResults') : null}
          valueKey="primaryKey"
          labelSuffix={showRefreshMessage ? refreshString : undefined}
          onClickLabelSuffixButton={showRefreshMessage && !readOnly ? handleRefresh : undefined}
          labelSuffixButtonText={
            isCustomFieldRefreshing
              ? t('edit.searchableCustomField.refreshing')
              : t('edit.searchableCustomField.refresh')
          }
          readOnly={readOnly}
          placeholder={
            !inputValue && (value?.length ?? 0 > 0)
              ? `Start typing ${customField.display_name || customField.name}…`
              : undefined
          }
          renderOption={(opt, { selected, highlighted, renderLocation, onDeselect }) => {
            const dependentValues = (key: string, { [key]: _, ...rest }) => rest
            const itemData = Object.entries(
              dependentValues((customField.display_name || customField.name) ?? '', opt.values)
            ).sort((a, b) => a[0].localeCompare(b[0]))
            const handleRemove = onDeselect && !readOnly ? () => onDeselect(opt) : undefined

            const extraContent = itemData
              .filter(([key]: [key: string, value: string | string[]]) => key !== customField.name)
              .map(([key, value]: [key: string, value: string | string[]]) => (
                <Text
                  tag="div"
                  key={key}
                  size="13px"
                  color="text-light"
                  css="text-wrap: wrap; padding: 2px 0; overflow-wrap: break-word;"
                >
                  <strong>{key}</strong>: {Array.isArray(value) ? value.join(', ') : value}
                </Text>
              ))

            // Generate a subtitle from the first 2 key/value pairs.
            // Note: this is not ideal since we have no measure of priority. Should make this customisable
            const subTitle = itemData.length > 0 ? itemData[0][1] : undefined

            // Note: These are currently the only multiselect items that have a grey bg when selected.
            // If this is going to continue, need to define a rule/reason why as can't be randomly different
            return (
              <ListItem
                onClickRemove={handleRemove}
                active={highlighted || selected}
                size={hideDependentFields || !subTitle ? 'medium' : 'large'}
                title={opt.primaryKey}
                subTitle={!hideDependentFields ? subTitle : undefined}
                prominence="high"
                expandable={!hideDependentFields && subTitle}
                expandableContent={!hideDependentFields && subTitle ? <>{extraContent}</> : undefined}
                css={`
                  margin-bottom: ${renderLocation === 'above' ? '1px' : undefined};
                `}
              />
            )
          }}
        />
      </Box>
    )
  }
)
